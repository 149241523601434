var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-expansion-panels',{staticClass:"mb-4 elevation-1"},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"text-h5 my-0 font-weight-bold",attrs:{"color":"#eeeeee"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(!_vm.shipmentIsNew && !_vm.view)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.editable = !_vm.editable}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e(),(!_vm.view)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteShipment()}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e(),_c('v-icon',[_vm._v("$expand")])]},proxy:true}])},[_vm._v(" Shipment #"+_vm._s(_vm.iterator)+" ")]),_c('v-expansion-panel-content',[_c('v-form',{staticClass:"py-2",attrs:{"disabled":!_vm.editable},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{staticClass:"mt-4",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pl-2",attrs:{"cols":"12","xl":"6","lg":"6","md":"6","sm":"6"}},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-invoice-text-outline","label":"Invoice Number","rules":[_vm.rules.required],"hide-details":""},on:{"input":function($event){return _vm.structureData()}},model:{value:(_vm.shipmentFormData.invoiceNumber),callback:function ($$v) {_vm.$set(_vm.shipmentFormData, "invoiceNumber", $$v)},expression:"shipmentFormData.invoiceNumber"}})],1),_c('v-col',{staticClass:"pl-2",attrs:{"cols":"12","xl":"6","lg":"6","md":"6","sm":"6"}},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-currency-usd","label":"TRM","type":"number","rules":[
                                    _vm.rules.required,
                                    _vm.rules.validateNumber,
                                ],"hide-details":""},on:{"input":event => {
                                        _vm.shipmentFormData.trm =
                                            parseFloat(event) || 0
                                        _vm.getTotalValues()
                                        _vm.structureData()
                                    }},model:{value:(_vm.shipmentFormData.trm),callback:function ($$v) {_vm.$set(_vm.shipmentFormData, "trm", $$v)},expression:"shipmentFormData.trm"}})],1)],1)],1),_c('v-data-table',{staticClass:"mt-4 elevation-1",attrs:{"headers":_vm.filteredHeaders,"items":_vm.itemsFromShipment,"item-value":"id","loading":_vm.quoteLoading,"mobile-breakpoint":0,"disable-pagination":"","hide-default-footer":"","fixed-header":""},scopedSlots:_vm._u([{key:`item.description`,fn:function({ item }){return [(!item.code)?_c('div',{staticClass:"d-flex justify-right"},[_c('v-select',{staticClass:"text-body-2 font-weight-regular pb-4",attrs:{"disabled":!_vm.editable,"dense":"","single-line":"","items":_vm.itemsAvailable,"item-text":"description","hide-details":"","hide-selected":"","rules":[_vm.rules.required],"menu-props":{
                                    bottom: true,
                                    offsetY: true,
                                }},on:{"change":() => {
                                        _vm.pushItem(item)
                                        _vm.getTotalValues()
                                        _vm.structureData(item.code)
                                    }},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}})],1):_c('div',{staticClass:"d-flex justify-center"},[_c('p',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(item.description)+" ")])])]}},{key:`item.ci`,fn:function({ item }){return [_c('div',[_c('v-text-field',{staticClass:"font-weight-regular text-body-2",attrs:{"disabled":!_vm.editable,"dense":"","single-line":"","regular":"","rules":[_vm.rules.required]},on:{"input":function($event){return _vm.structureData()}},model:{value:(item.ci),callback:function ($$v) {_vm.$set(item, "ci", $$v)},expression:"item.ci"}})],1)]}},{key:`item.oc`,fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-center"},[_c('p',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(item.oc)+" ")])])]}},{key:`item.weight`,fn:function({ item }){return [_c('div',[_c('v-text-field',{staticClass:"font-weight-regular text-body-2",attrs:{"disabled":!_vm.editable,"type":"number","dense":"","single-line":"","regular":"","rules":[
                                    _vm.rules.required,
                                    _vm.rules.validateNumber,
                                ],"suffix":"kg"},on:{"input":event => {
                                        item.weight = parseFloat(event) || 0
                                        _vm.getTotalValues()
                                        _vm.structureData()
                                    }},model:{value:(item.weight),callback:function ($$v) {_vm.$set(item, "weight", $$v)},expression:"item.weight"}})],1)]}},{key:`item.quantity`,fn:function({ item }){return [_c('div',[_c('v-text-field',{staticClass:"font-weight-regular text-body-2",attrs:{"disabled":!_vm.editable,"type":"number","dense":"","single-line":"","regular":"","rules":[
                                    _vm.rules.validateNumber,
                                    _vm.rules.required,
                                    _vm.rules.quantityRange(item),
                                ]},on:{"input":event => {
                                        item.quantity =
                                            parseFloat(event) || 0
                                        _vm.getTotalValues()
                                        _vm.structureData(item.code)
                                    }},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", $$v)},expression:"item.quantity"}})],1)]}},{key:`item.unitWeight`,fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-center"},[_c('p',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(item.weight && item.quantity ? ( item.weight / item.quantity ).toFixed(2) : '-')+" ")])])]}},{key:`item.unitPrice`,fn:function({ item }){return [_c('div',[_c('v-text-field',{staticClass:"font-weight-regular text-body-2",attrs:{"disabled":!_vm.editable,"type":"number","dense":"","single-line":"","regular":"","rules":[
                                    _vm.rules.required,
                                    _vm.rules.validateNumber,
                                ],"suffix":"$"},on:{"input":event => {
                                        item.unitPrice =
                                            parseFloat(event) || 0
                                        _vm.getTotalValues()
                                        _vm.structureData(item.code)
                                    }},model:{value:(item.unitPrice),callback:function ($$v) {_vm.$set(item, "unitPrice", $$v)},expression:"item.unitPrice"}})],1)]}},{key:`item.totalPrice`,fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-end"},[_c('p',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm.formatCurrency( 'USD', item.totalPrice || 0 ))+" ")])])]}},{key:`item.valueInCOP`,fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-end"},[_c('p',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm.formatCurrency( 'COP', item.totalPrice * _vm.shipmentFormData.trm || 0 ))+" ")])])]}},{key:`item.actions`,fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-center my-0"},[(_vm.editable)?_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1):_vm._e()],1)]}},{key:`body.append`,fn:function(){return [_c('td',{staticClass:"text-center",attrs:{"colspan":_vm.headers.length}},[_c('v-btn',{attrs:{"block":"","plain":"","disabled":_vm.validateNewShipment === 0 ||
                                        _vm.validateItems.length === 0},on:{"click":function($event){return _vm.addNewItem()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Add New Item ")],1)],1),_c('tr',{style:({ 'background-color': '#eeeeee' })},[(_vm.request.modality === 'Plan Vallejo')?_c('td',{staticClass:"font-weight-bold"}):_vm._e(),_c('td',{staticClass:"font-weight-bold"}),_c('td',{staticClass:"font-weight-bold justify-end"},[_vm._v(" TOTALS ")]),_c('td',{staticClass:"font-weight-bold"},[_c('p',{staticClass:"d-flex justify-center align-right my-0"},[_vm._v(" "+_vm._s(_vm.totalWeight.toFixed(2))+" kg ")])]),_c('td',{staticClass:"font-weight-bold"},[_c('p',{staticClass:"d-flex justify-center align-right my-0"},[_vm._v(" "+_vm._s(_vm.totalQuantity.toFixed(2))+" ")])]),_c('td',{staticClass:"font-weight-bold"}),_c('td',{staticClass:"font-weight-bold"}),_c('td',{staticClass:"font-weight-bold"},[_c('p',{staticClass:"d-flex justify-end align-right my-0"},[_vm._v(" "+_vm._s(_vm.formatCurrency('USD', _vm.totalUSD))+" ")])]),_c('td',{staticClass:"font-weight-bold"},[_c('p',{staticClass:"d-flex justify-end align-right my-0"},[_vm._v(" "+_vm._s(_vm.formatCurrency('COP', _vm.totalCOP))+" ")])]),_c('td',{staticClass:"font-weight-bold"})])]},proxy:true}],null,true)}),_c('v-form',{staticClass:"py-2",attrs:{"disabled":!_vm.editable},model:{value:(_vm.valid2),callback:function ($$v) {_vm.valid2=$$v},expression:"valid2"}},[_c('v-row',{staticClass:"mt-4",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pl-2",attrs:{"cols":"12","xl":"3","lg":"3","md":"3","sm":"6"}},[_c('v-select',{attrs:{"items":_vm.shipmentData.shipmentType,"label":"Shipment Type","hide-details":"","prepend-icon":"mdi-plane-train","hide-selected":"","rules":[_vm.rules.required],"menu-props":{
                                    bottom: true,
                                    offsetY: true,
                                }},on:{"input":function($event){return _vm.structureData()}},model:{value:(_vm.shipmentFormData.shipmentType),callback:function ($$v) {_vm.$set(_vm.shipmentFormData, "shipmentType", $$v)},expression:"shipmentFormData.shipmentType"}})],1)],1),_c('v-row',{staticClass:"my-1 mt-0",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pl-2",attrs:{"cols":"12","xl":"3","lg":"3","md":"3","sm":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Departure Date","prepend-icon":"mdi-airplane-clock","readonly":"","hide-details":""},model:{value:(_vm.shipmentFormData.depDate),callback:function ($$v) {_vm.$set(_vm.shipmentFormData, "depDate", $$v)},expression:"shipmentFormData.depDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{on:{"input":() => {
                                            _vm.structureData(), (_vm.menu = false)
                                        }},model:{value:(_vm.shipmentFormData.depDate),callback:function ($$v) {_vm.$set(_vm.shipmentFormData, "depDate", $$v)},expression:"shipmentFormData.depDate"}})],1)],1),_c('v-col',{staticClass:"pl-2",attrs:{"cols":"12","xl":"3","lg":"3","md":"3","sm":"6"}},[_c('v-select',{attrs:{"items":_vm.shipmentData.arrivalPort,"label":"Port of Arrival","hide-details":"","prepend-icon":"mdi-plane-train","hide-selected":"","rules":[_vm.rules.required],"menu-props":{
                                    bottom: true,
                                    offsetY: true,
                                }},on:{"input":function($event){return _vm.structureData()}},model:{value:(_vm.shipmentFormData.arrivalPort),callback:function ($$v) {_vm.$set(_vm.shipmentFormData, "arrivalPort", $$v)},expression:"shipmentFormData.arrivalPort"}})],1),_c('v-col',{staticClass:"pl-2",attrs:{"cols":"12","xl":"3","lg":"3","md":"3","sm":"6"}},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-shipping-pallet","label":"Shipping Line","rules":[_vm.rules.required],"hide-details":""},on:{"input":function($event){return _vm.structureData()}},model:{value:(_vm.shipmentFormData.ShippingLine),callback:function ($$v) {_vm.$set(_vm.shipmentFormData, "ShippingLine", $$v)},expression:"shipmentFormData.ShippingLine"}})],1),_c('v-col',{staticClass:"pl-2",attrs:{"cols":"12","xl":"3","lg":"3","md":"3","sm":"6"}},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-truck-cargo-container","label":"Motonave","rules":[_vm.rules.required],"hide-details":""},on:{"input":function($event){return _vm.structureData()}},model:{value:(_vm.shipmentFormData.motonave),callback:function ($$v) {_vm.$set(_vm.shipmentFormData, "motonave", $$v)},expression:"shipmentFormData.motonave"}})],1)],1),_c('v-row',{staticClass:"my-1 mt-0",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pl-2",attrs:{"cols":"12","xl":"3","lg":"3","md":"3","sm":"6"}},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-calendar-clock","label":"Transit Time in days","type":"number","rules":[_vm.rules.required, _vm.rules.number1],"hide-details":""},on:{"input":event => {
                                        _vm.shipmentFormData.transitTime =
                                            parseFloat(event) || 0
                                        _vm.structureData()
                                    }},model:{value:(_vm.shipmentFormData.transitTime),callback:function ($$v) {_vm.$set(_vm.shipmentFormData, "transitTime", $$v)},expression:"shipmentFormData.transitTime"}})],1),_c('v-col',{staticClass:"pl-2",attrs:{"cols":"12","xl":"3","lg":"3","md":"3","sm":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Estimated Date of Arrival","prepend-icon":"mdi-calendar-check","readonly":"","hide-details":""},model:{value:(
                                            _vm.shipmentFormData.estimatedDateArrival
                                        ),callback:function ($$v) {_vm.$set(_vm.shipmentFormData, "estimatedDateArrival", $$v)},expression:"\n                                            shipmentFormData.estimatedDateArrival\n                                        "}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu3),callback:function ($$v) {_vm.menu3=$$v},expression:"menu3"}},[_c('v-date-picker',{on:{"input":() => {
                                            _vm.structureData(), (_vm.menu3 = false)
                                        }},model:{value:(
                                        _vm.shipmentFormData.estimatedDateArrival
                                    ),callback:function ($$v) {_vm.$set(_vm.shipmentFormData, "estimatedDateArrival", $$v)},expression:"\n                                        shipmentFormData.estimatedDateArrival\n                                    "}})],1)],1),_c('v-col',{staticClass:"pl-2",attrs:{"cols":"12","xl":"3","lg":"3","md":"3","sm":"6"}},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-invoice-import-outline","label":"DIM Number","rules":[_vm.rules.required],"hide-details":""},on:{"input":function($event){return _vm.structureData()}},model:{value:(_vm.shipmentFormData.numberDim),callback:function ($$v) {_vm.$set(_vm.shipmentFormData, "numberDim", $$v)},expression:"shipmentFormData.numberDim"}})],1)],1),_c('v-row',{staticClass:"my-1 mt-0",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pl-2",attrs:{"cols":"12","xl":"3","lg":"3","md":"3","sm":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Acceptance Date DIM","prepend-icon":"mdi-calendar-check","readonly":"","hide-details":""},model:{value:(
                                            _vm.shipmentFormData.acceptanceDateDIM
                                        ),callback:function ($$v) {_vm.$set(_vm.shipmentFormData, "acceptanceDateDIM", $$v)},expression:"\n                                            shipmentFormData.acceptanceDateDIM\n                                        "}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{on:{"input":() => {
                                            _vm.structureData(), (_vm.menu1 = false)
                                        }},model:{value:(
                                        _vm.shipmentFormData.acceptanceDateDIM
                                    ),callback:function ($$v) {_vm.$set(_vm.shipmentFormData, "acceptanceDateDIM", $$v)},expression:"\n                                        shipmentFormData.acceptanceDateDIM\n                                    "}})],1)],1),_c('v-col',{staticClass:"pl-2",attrs:{"cols":"12","xl":"3","lg":"3","md":"3","sm":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Dim Lift Date","prepend-icon":"mdi-calendar-check","readonly":"","hide-details":""},model:{value:(_vm.shipmentFormData.DateOfDIM),callback:function ($$v) {_vm.$set(_vm.shipmentFormData, "DateOfDIM", $$v)},expression:"shipmentFormData.DateOfDIM"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu4),callback:function ($$v) {_vm.menu4=$$v},expression:"menu4"}},[_c('v-date-picker',{on:{"input":() => {
                                            _vm.structureData(), (_vm.menu4 = false)
                                        }},model:{value:(_vm.shipmentFormData.DateOfDIM),callback:function ($$v) {_vm.$set(_vm.shipmentFormData, "DateOfDIM", $$v)},expression:"shipmentFormData.DateOfDIM"}})],1)],1),_c('v-col',{staticClass:"pl-2",attrs:{"cols":"12","xl":"3","lg":"3","md":"3","sm":"6"}},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-cash","label":"IVA DIM","type":"number","rules":[
                                    _vm.rules.required,
                                    _vm.rules.validateNumber,
                                ],"hide-details":""},on:{"input":event => {
                                        _vm.shipmentFormData.ivaDim =
                                            parseFloat(event) || 0
                                        _vm.structureData()
                                    }},model:{value:(_vm.shipmentFormData.ivaDim),callback:function ($$v) {_vm.$set(_vm.shipmentFormData, "ivaDim", $$v)},expression:"shipmentFormData.ivaDim"}})],1)],1),_c('v-row',{staticClass:"my-1 mt-0",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pl-2",attrs:{"cols":"12","xl":"3","lg":"3","md":"3","sm":"6"}},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-train-car-container","label":"Container Number","rules":[_vm.rules.required],"hide-details":""},on:{"input":function($event){return _vm.structureData()}},model:{value:(_vm.shipmentFormData.containerNumber),callback:function ($$v) {_vm.$set(_vm.shipmentFormData, "containerNumber", $$v)},expression:"shipmentFormData.containerNumber"}})],1),_c('v-col',{staticClass:"pl-2",attrs:{"cols":"12","xl":"3","lg":"3","md":"3","sm":"6"}},[_c('v-select',{attrs:{"items":_vm.shipmentData.containerSize,"label":"Container Size","hide-details":"","prepend-icon":"mdi-train-car-container","hide-selected":"","rules":[_vm.rules.required],"menu-props":{
                                    bottom: true,
                                    offsetY: true,
                                }},on:{"input":function($event){return _vm.structureData()}},model:{value:(_vm.shipmentFormData.containerSize),callback:function ($$v) {_vm.$set(_vm.shipmentFormData, "containerSize", $$v)},expression:"shipmentFormData.containerSize"}})],1),_c('v-col',{staticClass:"pl-2",attrs:{"cols":"12","xl":"3","lg":"3","md":"3","sm":"6"}},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-text-box-outline","label":"Transport Document","rules":[_vm.rules.required],"hide-details":""},on:{"input":function($event){return _vm.structureData()}},model:{value:(_vm.shipmentFormData.transportDoc),callback:function ($$v) {_vm.$set(_vm.shipmentFormData, "transportDoc", $$v)},expression:"shipmentFormData.transportDoc"}})],1),_c('v-col',{staticClass:"pl-2",attrs:{"cols":"12","xl":"3","lg":"3","md":"3","sm":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date of Transport Document","prepend-icon":"mdi-calendar-check","readonly":"","hide-details":""},model:{value:(
                                            _vm.shipmentFormData.dateDocTransport
                                        ),callback:function ($$v) {_vm.$set(_vm.shipmentFormData, "dateDocTransport", $$v)},expression:"\n                                            shipmentFormData.dateDocTransport\n                                        "}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu6),callback:function ($$v) {_vm.menu6=$$v},expression:"menu6"}},[_c('v-date-picker',{on:{"input":() => {
                                            _vm.structureData(), (_vm.menu6 = false)
                                        }},model:{value:(
                                        _vm.shipmentFormData.dateDocTransport
                                    ),callback:function ($$v) {_vm.$set(_vm.shipmentFormData, "dateDocTransport", $$v)},expression:"\n                                        shipmentFormData.dateDocTransport\n                                    "}})],1)],1)],1),_c('v-row',{staticClass:"my-1 mt-0",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pl-2",attrs:{"cols":"12","xl":"3","lg":"3","md":"3","sm":"6"}},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-currency-usd","label":"FOB DIM Value","type":"number","rules":[
                                    _vm.rules.required,
                                    _vm.rules.validateNumber,
                                ],"hide-details":""},on:{"input":event => {
                                        _vm.shipmentFormData.fobDimValue =
                                            parseFloat(event) || 0
                                        _vm.structureData()
                                    }},model:{value:(_vm.shipmentFormData.fobDimValue),callback:function ($$v) {_vm.$set(_vm.shipmentFormData, "fobDimValue", $$v)},expression:"shipmentFormData.fobDimValue"}})],1),_c('v-col',{staticClass:"pl-2",attrs:{"cols":"12","xl":"3","lg":"3","md":"3","sm":"6"}},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-currency-usd","label":"FOB Unit Value","type":"number","rules":[
                                    _vm.rules.required,
                                    _vm.rules.validateNumber,
                                ],"hide-details":""},on:{"input":event => {
                                        _vm.shipmentFormData.unitFOB =
                                            parseFloat(event) || 0

                                        _vm.structureData()
                                    }},model:{value:(_vm.shipmentFormData.unitFOB),callback:function ($$v) {_vm.$set(_vm.shipmentFormData, "unitFOB", $$v)},expression:"shipmentFormData.unitFOB"}})],1)],1),_c('v-row',{staticClass:"my-1 mt-0",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pl-2",attrs:{"cols":"12","xl":"3","lg":"3","md":"3","sm":"6"}},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-currency-usd","type":"number","label":"Freight Value","rules":[
                                    _vm.rules.required,
                                    _vm.rules.validateNumber,
                                ],"hide-details":""},on:{"input":event => {
                                        _vm.shipmentFormData.freight =
                                            parseFloat(event) || 0
                                        _vm.structureData()
                                    }},model:{value:(_vm.shipmentFormData.freight),callback:function ($$v) {_vm.$set(_vm.shipmentFormData, "freight", $$v)},expression:"shipmentFormData.freight"}})],1),_c('v-col',{staticClass:"pl-2",attrs:{"cols":"12","xl":"3","lg":"3","md":"3","sm":"6"}},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-currency-usd","label":"Insurance","type":"number","rules":[
                                    _vm.rules.required,
                                    _vm.rules.validateNumber,
                                ],"hide-details":""},on:{"input":event => {
                                        _vm.shipmentFormData.insurance =
                                            parseFloat(event) || 0
                                        _vm.structureData()
                                    }},model:{value:(_vm.shipmentFormData.insurance),callback:function ($$v) {_vm.$set(_vm.shipmentFormData, "insurance", $$v)},expression:"shipmentFormData.insurance"}})],1),_c('v-col',{staticClass:"pl-2",attrs:{"cols":"12","xl":"3","lg":"3","md":"3","sm":"6"}},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-currency-usd","label":"Other Costs","type":"number","rules":[
                                    _vm.rules.required,
                                    _vm.rules.validateNumber,
                                ],"hide-details":""},on:{"input":event => {
                                        _vm.shipmentFormData.otherCosts =
                                            parseFloat(event) || 0
                                        _vm.structureData()
                                    }},model:{value:(_vm.shipmentFormData.otherCosts),callback:function ($$v) {_vm.$set(_vm.shipmentFormData, "otherCosts", $$v)},expression:"shipmentFormData.otherCosts"}})],1)],1),_c('v-row',{staticClass:"my-4 mt-4",attrs:{"no-gutters":""}},[_c('v-spacer')],1),_c('v-row',{staticClass:"my-1 mt-0",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pl-2",attrs:{"cols":"12","xl":"3","lg":"3","md":"3","sm":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Actual Date of Arrival","prepend-icon":"mdi-calendar-check","readonly":"","hide-details":""},model:{value:(
                                            _vm.shipmentFormData.realDateArrival
                                        ),callback:function ($$v) {_vm.$set(_vm.shipmentFormData, "realDateArrival", $$v)},expression:"\n                                            shipmentFormData.realDateArrival\n                                        "}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{on:{"input":() => {
                                            _vm.structureData(), (_vm.menu2 = false)
                                        }},model:{value:(
                                        _vm.shipmentFormData.realDateArrival
                                    ),callback:function ($$v) {_vm.$set(_vm.shipmentFormData, "realDateArrival", $$v)},expression:"\n                                        shipmentFormData.realDateArrival\n                                    "}})],1)],1),_c('v-col',{staticClass:"pl-2",attrs:{"cols":"12","xl":"3","lg":"3","md":"3","sm":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date Entered Warehouse","prepend-icon":"mdi-calendar-check","readonly":"","hide-details":""},model:{value:(
                                            _vm.shipmentFormData.enteredDate
                                        ),callback:function ($$v) {_vm.$set(_vm.shipmentFormData, "enteredDate", $$v)},expression:"\n                                            shipmentFormData.enteredDate\n                                        "}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu5),callback:function ($$v) {_vm.menu5=$$v},expression:"menu5"}},[_c('v-date-picker',{on:{"input":() => {
                                            _vm.structureData(), (_vm.menu5 = false)
                                        }},model:{value:(_vm.shipmentFormData.enteredDate),callback:function ($$v) {_vm.$set(_vm.shipmentFormData, "enteredDate", $$v)},expression:"shipmentFormData.enteredDate"}})],1)],1),_c('v-col'),_c('v-col',{staticClass:"pl-2",attrs:{"cols":"12","xl":"3","lg":"3","md":"3","sm":"6"}},[_c('v-text-field',{attrs:{"readonly":"","disabled":"","prepend-icon":"mdi-currency-usd","label":"Total Customs Value","rules":[
                                    _vm.rules.required,
                                    _vm.rules.validateNumber,
                                ],"hide-details":""},model:{value:(_vm.computedTotalCustoms),callback:function ($$v) {_vm.computedTotalCustoms=$$v},expression:"computedTotalCustoms"}})],1)],1)],1),_c('v-row',{staticClass:"pb-1 pt-4 mt-3"},[_c('div',{staticClass:"d-flex align-right pl-4"},[_c('strong',[_vm._v("ATTACHMENTS: ")])]),_c('v-spacer')],1),_c('div',{staticClass:"pb-1 pt-3 mt-3"},[_c('v-row',{ref:"invoice-files"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex my-2"},[_c('div',{staticClass:"d-flex align-center pl-1"},[_c('strong',[_vm._v("INVOICE: ")])]),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_vm._l((_vm.shipmentFormData.attachedInvoice),function(file,i){return _c('v-btn',{key:i,staticClass:"ma-1 text-capitalize text--darken-2 pa-2",attrs:{"color":"transparent","elevation":"0","small":""}},[_c('v-icon',{staticClass:"mr-1",style:({ 'font-size': '17px' }),attrs:{"color":"primary"}},[_vm._v(" mdi-paperclip ")]),_c('span',{style:({
                                                'font-size': '11px',
                                                color: '#2b81d6',
                                                'text-decoration':
                                                    'underline',
                                                cursor: 'pointer',
                                            }),on:{"click":function($event){$event.stopPropagation();return _vm.openAttachedFile(
                                                    'attachedInvoice',
                                                    file
                                                )}}},[_vm._v(_vm._s(file))]),(!_vm.view)?_c('v-icon',{staticClass:"ml-1",attrs:{"disabled":!_vm.editable,"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteFile(
                                                    'attachedInvoice',
                                                    file
                                                )}}},[_vm._v(" mdi-close-circle ")]):_vm._e()],1)}),_c('v-btn',{staticClass:"ml-2",attrs:{"disabled":!_vm.editable,"color":"accent","icon":"","plain":""},on:{"click":function($event){$event.stopPropagation();return _vm.onButtonClick('attachedInvoice')}}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('input',{ref:"attachedInvoice",staticClass:"d-none",attrs:{"type":"file","name":"attachedInvoice"},on:{"change":function($event){return _vm.onFileChanged($event)}}})],2)])])],1),_c('v-row',{ref:"dim-files"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex my-2"},[_c('div',{staticClass:"d-flex align-center pl-1"},[_c('strong',[_vm._v("DIM: ")])]),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_vm._l((_vm.shipmentFormData.attachedDIM),function(file,i){return _c('v-btn',{key:i,staticClass:"ma-1 text-capitalize text--darken-2 pa-2",attrs:{"color":"transparent","elevation":"0","small":""}},[_c('v-icon',{staticClass:"mr-1",style:({ 'font-size': '17px' }),attrs:{"color":"primary"}},[_vm._v(" mdi-paperclip ")]),_c('span',{style:({
                                                'font-size': '11px',
                                                color: '#2b81d6',
                                                'text-decoration':
                                                    'underline',
                                                cursor: 'pointer',
                                            }),on:{"click":function($event){$event.stopPropagation();return _vm.openAttachedFile(
                                                    'attachedDIM',
                                                    file
                                                )}}},[_vm._v(_vm._s(file))]),(!_vm.view)?_c('v-icon',{staticClass:"ml-1",attrs:{"disabled":!_vm.editable,"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteFile(
                                                    'attachedDIM',
                                                    file
                                                )}}},[_vm._v(" mdi-close-circle ")]):_vm._e()],1)}),_c('v-btn',{staticClass:"ml-2",attrs:{"disabled":!_vm.editable,"color":"accent","icon":"","plain":""},on:{"click":function($event){$event.stopPropagation();return _vm.onButtonClick('attachedDIM')}}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('input',{ref:"attachedDIM",staticClass:"d-none",attrs:{"type":"file","name":"attachedDIM"},on:{"change":function($event){return _vm.onFileChanged($event)}}})],2)])])],1),_c('v-row',{ref:"transport-files"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex my-2"},[_c('div',{staticClass:"d-flex align-center pl-1"},[_c('strong',[_vm._v("DOC TRANSPORT: ")])]),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_vm._l((_vm.shipmentFormData.attachedDocTransport),function(file,i){return _c('v-btn',{key:i,staticClass:"ma-1 text-capitalize text--darken-2 pa-2",attrs:{"color":"transparent","elevation":"0","small":""}},[_c('v-icon',{staticClass:"mr-1",style:({ 'font-size': '17px' }),attrs:{"color":"primary"}},[_vm._v(" mdi-paperclip ")]),_c('span',{style:({
                                                'font-size': '11px',
                                                color: '#2b81d6',
                                                'text-decoration':
                                                    'underline',
                                                cursor: 'pointer',
                                            }),on:{"click":function($event){$event.stopPropagation();return _vm.openAttachedFile(
                                                    'attachedDocTransport',
                                                    file
                                                )}}},[_vm._v(_vm._s(file))]),(!_vm.view)?_c('v-icon',{staticClass:"ml-1",attrs:{"disabled":!_vm.editable,"color":"grey darken-2","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteFile(
                                                    'attachedDocTransport',
                                                    file
                                                )}}},[_vm._v(" mdi-close-circle ")]):_vm._e()],1)}),_c('v-btn',{staticClass:"ml-2",attrs:{"disabled":!_vm.editable,"color":"accent","icon":"","plain":""},on:{"click":function($event){$event.stopPropagation();return _vm.onButtonClick(
                                                'attachedDocTransport'
                                            )}}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('input',{ref:"attachedDocTransport",staticClass:"d-none",attrs:{"type":"file","name":"attachedDocTransport"},on:{"change":function($event){return _vm.onFileChanged($event)}}})],2)])])],1),_c('v-row',{ref:"other-files"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex my-2"},[_c('div',{staticClass:"d-flex align-center pl-1"},[_c('strong',[_vm._v("OTHER FILES: ")])]),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_vm._l((_vm.shipmentFormData.attachedOtherFiles),function(file,i){return _c('v-btn',{key:i,staticClass:"ma-1 text-capitalize text--darken-2 pa-2",attrs:{"color":"transparent","elevation":"0","small":""}},[_c('v-icon',{staticClass:"mr-1",style:({ 'font-size': '17px' }),attrs:{"color":"primary"}},[_vm._v(" mdi-paperclip ")]),_c('span',{style:({
                                                'font-size': '11px',
                                                color: '#2b81d6',
                                                'text-decoration':
                                                    'underline',
                                                cursor: 'pointer',
                                            }),on:{"click":function($event){$event.stopPropagation();return _vm.openAttachedFile(
                                                    'attachedOtherFiles',
                                                    file
                                                )}}},[_vm._v(_vm._s(file))]),(!_vm.view)?_c('v-icon',{staticClass:"ml-1",attrs:{"disabled":!_vm.editable,"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteFile(
                                                    'pattachedOtherFiles',
                                                    file
                                                )}}},[_vm._v(" mdi-close-circle ")]):_vm._e()],1)}),_c('v-btn',{staticClass:"ml-2",attrs:{"disabled":!_vm.editable,"color":"accent","icon":"","plain":""},on:{"click":function($event){$event.stopPropagation();return _vm.onButtonClick(
                                                'attachedOtherFiles'
                                            )}}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('input',{ref:"attachedOtherFiles",staticClass:"d-none",attrs:{"type":"file","name":"attachedOtherFiles"},on:{"change":function($event){return _vm.onFileChanged($event)}}})],2)])])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div
        style="height: 100vh; display: flex; flex-direction: column; overflow-y: scroll;"
    >
        <v-card id="details" :loading="loading" style="flex-grow: 1;">
            <v-card-title ref="title" class="sticky-header">
                <v-btn small icon @click="close" color="primary" class="mr-2">
                    <v-icon>
                        mdi-close
                    </v-icon>
                </v-btn>
                <h3>
                    {{ 'Foreign Trade Module' }}
                </h3>
                <v-btn
                    v-if="!view"
                    @click="print"
                    small
                    icon
                    color="primary"
                    class="ml-2"
                >
                    <v-icon> mdi-file-pdf-box </v-icon>
                </v-btn>
                <v-btn
                    v-if="view"
                    @click="showCOMEXFile"
                    small
                    icon
                    color="primary"
                    class="ml-2"
                >
                    <v-icon> mdi-file-pdf-box </v-icon>
                </v-btn>

                <v-spacer />
                <v-btn
                    rounded
                    v-if="!view"
                    :loading="loading2"
                    color="primary"
                    class="mx-2"
                    :disabled="!validateGeneralForm"
                    @click="saveComexForm"
                >
                    SAVE
                </v-btn>
            </v-card-title>
            <v-divider class="ml-4 mr-5" />
            <v-card-text class="pt-0">
                <v-form v-model="valid" class="py-2">
                    <v-row no-gutters class="mt-4">
                        <v-col
                            cols="12"
                            xl="3"
                            lg="3"
                            md="3"
                            sm="6"
                            class="pl-2"
                        >
                            <v-text-field
                                disabled
                                :value="formattedSuppliers"
                                prepend-icon="mdi-store"
                                label="Suppliers"
                                hide-details
                                readonly
                            />
                        </v-col>

                        <v-col
                            cols="12"
                            xl="3"
                            lg="3"
                            md="3"
                            sm="6"
                            class="pl-2"
                        >
                            <v-text-field
                                disabled
                                v-model="request.number"
                                prepend-icon="mdi-import"
                                label="Importation Number"
                                readonly
                                hide-details
                            />
                        </v-col>

                        <v-col
                            cols="12"
                            xl="3"
                            lg="3"
                            md="3"
                            sm="6"
                            class="pl-2"
                        >
                            <v-text-field
                                disabled
                                v-model="request.project.name"
                                prepend-icon="mdi-head-lightbulb"
                                label="Project"
                                readonly
                                hide-details
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            xl="3"
                            lg="3"
                            md="3"
                            sm="6"
                            class="pl-2"
                        >
                            <v-text-field
                                disabled
                                v-model="formattedYear"
                                prepend-icon="mdi-calendar-blank"
                                label="Year"
                                readonly
                                hide-details
                            />
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="mt-4">
                        <v-col
                            cols="12"
                            xl="3"
                            lg="3"
                            md="3"
                            sm="4"
                            class="pl-2"
                        >
                            <v-autocomplete
                                :disabled="view"
                                v-model="request.originCountry"
                                :items="this.countries"
                                prepend-icon="mdi-earth"
                                :rules="[rules.required]"
                                label="Country of origin"
                                @change="selectState"
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            xl="3"
                            lg="3"
                            md="3"
                            sm="6"
                            class="pl-2"
                        >
                            <v-autocomplete
                                :disabled="view"
                                v-model="request.originState"
                                @change="selectCity"
                                :items="this.states"
                                :rules="[rules.required]"
                                prepend-icon="mdi-map-marker-radius"
                                label="State of origin"
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            xl="3"
                            lg="3"
                            md="3"
                            sm="6"
                            class="pl-2"
                        >
                            <v-combobox
                                :disabled="view"
                                v-model="request.originCity"
                                :items="this.cities"
                                label="City of origin"
                                placeholder="Select or create one"
                                :rules="[rules.required]"
                                hide-details
                                prepend-icon="mdi-city"
                                hide-selected
                            >
                            </v-combobox>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="my-1 mt-0">
                        <v-col
                            cols="12"
                            xl="3"
                            lg="3"
                            md="3"
                            sm="6"
                            class="pl-2"
                        >
                            <v-select
                                :disabled="view"
                                v-model="request.incoterm"
                                :items="formattedIncoterms"
                                label="Select a incoterm"
                                item-text="text"
                                item-value="value"
                                hide-details
                                prepend-icon="mdi-receipt-text-check-outline"
                                hide-selected
                                :rules="[rules.required]"
                                :menu-props="{ bottom: true, offsetY: true }"
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            xl="3"
                            lg="3"
                            md="3"
                            sm="6"
                            class="pl-2"
                        >
                            <v-select
                                :disabled="view"
                                v-model="request.modality"
                                :items="comexData.modality"
                                label="Select a modality"
                                hide-details
                                prepend-icon="mdi-receipt-text-outline"
                                hide-selected
                                :rules="[rules.required]"
                                :menu-props="{ bottom: true, offsetY: true }"
                            />
                        </v-col>
                        <v-col
                            v-if="request.modality === 'Plan Vallejo'"
                            cols="12"
                            xl="3"
                            lg="3"
                            md="3"
                            sm="6"
                            class="pl-2"
                        >
                            <v-menu
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                v-model="menu3"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        :disabled="view"
                                        v-model="request.maxExpDate"
                                        :rules="[rules.required]"
                                        label="Max Export Date"
                                        prepend-icon="mdi-receipt-text-clock-outline"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        hide-details
                                        class="pl-2"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="request.maxExpDate"
                                    @input="menu3 = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                </v-form>
                <v-row>
                    <v-spacer />
                    <v-btn
                        v-if="!view"
                        rounded
                        color="primary"
                        class="mt-4 mr-3 white--text"
                        hide-details
                        @click="createShipmentForm"
                        :disabled="validateNewShipment === 0"
                    >
                        create a shipment
                    </v-btn>
                </v-row>
                <v-data-table
                    class="elevation-2 mt-6"
                    :headers="headers"
                    :items="request.items"
                    :loading="loading"
                    :mobile-breakpoint="0"
                    disable-pagination
                    hide-default-footer
                    fixed-header
                >
                    <template v-slot:top>
                        <v-row
                            no-gutter
                            class="pa-0 ma-0"
                            :style="{ 'background-color': '#eeeeee' }"
                        >
                            <v-col cols="12" class="d-flex">
                                <p class="text-h5 my-0 pl-3 font-weight-bold">
                                    Received Items
                                </p>
                            </v-col>
                        </v-row>
                    </template>
                    <!--ITEMS-->
                    <template v-slot:[`item.index`]="{ index }">
                        <p class="mt-3 font-weight-medium">
                            {{ index + 1 }}
                        </p>
                    </template>
                    <template v-slot:[`item.code`]="{ item }">
                        <p class="mt-3 font-weight-medium">
                            {{ item.code }}
                        </p>
                    </template>
                    <template v-slot:[`item.description`]="{ item }">
                        <p class="mt-3 font-weight-medium">
                            {{ item.description }}
                        </p>
                    </template>
                    <template v-slot:[`item.quantity`]="{ item }">
                        <p class="mt-3 font-weight-medium">
                            {{ item.quantity }}
                        </p>
                    </template>
                    <template v-slot:[`item.received`]="{ item }">
                        <p :class="validateItemQty(item)">
                            {{ getReceived()(item.code) }}
                        </p>
                    </template>
                    <template v-slot:[`item.processed`]="{ item }">
                        <p :class="validateItemProcessed(item.code)">
                            {{ getProcessed()(item.code) }}
                        </p>
                    </template>
                    <template v-slot:[`item.supplier`]="{ item }">
                        <p class="my-0 text-capitalize font-weight-medium">
                            {{ getSupplier(item) }}
                        </p>
                    </template>
                    <template v-slot:[`item.order`]="{ item }">
                        <a
                            v-if="item.purchaseOrder && item.purchaseOrder.file"
                            @click="handleOpenFile(item)"
                            >{{ item.purchaseOrder.orderNumber }}</a
                        >
                        <p v-else class="font-weight-medium my-0">
                            {{
                                item.purchaseOrder
                                    ? item.purchaseOrder.orderNumber
                                    : ''
                            }}
                        </p>
                    </template>
                    <template v-slot:[`item.price`]="{ item }">
                        <p class="font-weight-medium mt-3" v-if="item.quotes">
                            {{
                                item.quotes.find(quote => quote.able)
                                    ? numberFormat(
                                          item.quotes.find(quote => quote.able)
                                              .price
                                      )
                                    : ''
                            }}
                        </p>
                    </template>
                    <template v-slot:[`item.totalPrice`]="{ item }">
                        <p class="font-weight-medium mt-3" v-if="item.quotes">
                            {{
                                item.quotes.find(quote => quote.able)
                                    ? numberFormat(
                                          item.quotes.find(quote => quote.able)
                                              .price * item.quantity
                                      )
                                    : ''
                            }}
                        </p>
                    </template>
                    <!-- FOOTER -->
                    <template v-slot:[`body.append`]>
                        <tr :style="{ 'background-color': '#eeeeee' }">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td class="font-weight-bold">
                                <p
                                    class="d-flex justify-center align-right my-0"
                                >
                                    TOTAL:
                                </p>
                            </td>
                            <td class="font-weight-bold">
                                <p
                                    class="d-flex justify-center align-right my-0"
                                >
                                    {{ numberFormat(request.totalPrice || 0) }}
                                </p>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
                <v-spacer></v-spacer>
                <ComexShipmentForm
                    class="mt-4 elevation-1"
                    v-for="shipment in shipments"
                    :key="shipment.id + '-' + shipmentKey"
                    :newShipment="shipment.newShipment"
                    :isEditable="shipment.editable"
                    :iterator="shipment.iterator"
                    :shipmentId="shipment.id"
                    :request="request"
                    :items="shipment.items"
                    :maxValues="itemValues"
                    :view="view"
                    :validateNewShipment="validateNewShipment"
                    :formShipment="shipment.shipmentFormData"
                    @deleteShipment="deleteShipment"
                    @deleteFile="deleteFile"
                    @openFile="openAttachedFile"
                    @comexShipment="comexShipment"
                />
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn
                    v-if="!view"
                    color="primary"
                    rounded
                    class="mt-n2 mr-3 white--text"
                    :loading="loading"
                    @click="finalizeComex"
                    :disabled="
                        validateGeneralForm ||
                            !validateFinalizeCOMEX ||
                            (this.request.status != 'received' &&
                                this.request.status != 'closed')
                    "
                >
                    Finalize COMEX
                </v-btn>
                <v-btn
                    v-if="
                        this.request.status === 'closed' &&
                            view &&
                            !this.request.closeByCOMEX &&
                            !this.request.closeNoComex
                    "
                    color="primary"
                    rounded
                    class="mt-n2 mr-3 white--text"
                    :loading="loading"
                    @click="closeByCOMEX"
                >
                    Close by COMEX
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>
<script>
import { deleteFile } from '@/services/storage/deleteFile.js'
import { saveFile } from '@/services/storage/saveFile.js'
import { openFile } from '@/services/storage/openFile.js'
import { mapMutations } from 'vuex'
import { printCOMEXPDF } from './ComexPDF'
import { storage } from '@/services/firebase'
import { objDiff } from '@/helpers/objDiff.js'
import _ from 'lodash'
import API from '@/services/api'
import ComexShipmentForm from '@/components/Comex/ComexShipmentForm.vue'
const countrycitystate = require('countrycitystatejson')
const { v4: uuidv4 } = require('uuid')

export default {
    name: 'ComexForm',
    components: {
        ComexShipmentForm,
    },
    props: {
        purchase: {
            type: Object,
            default: () => ({ type: 'material', items: [] }),
            required: true,
        },
        view: {
            type: Boolean,
            default: () => false,
        },
    },
    data: () => ({
        loading: false,
        loading2: false,
        valid: false,
        menu3: false,
        rules: {
            required: v => !!v || 'Required',
            number2: v => v > 0 || 'Error',
            number: v => v >= 0 || 'Error',
            optionalNumber: v => !v || v == '' || v > 0 || 'Invalid number',
            validateNumber: v => {
                const numberValue = parseFloat(v)
                return (
                    (!isNaN(numberValue) && numberValue > 0) || 'Invalid number'
                )
            },
        },
        comexData: {
            incoterms: {
                'Ex Works': 'EXW',
                'Free Carrier': 'FCA',
                'Free Alongside Ship': 'FAS',
                'Free On Board': 'FOB',
                'Cost And Freight': 'CFR',
                'Cost, Insurance, and Freight': 'CIF',
                'Carriage Paid To': 'CPT',
                'Carriage Insurance Paid To': 'CIP',
                'Delivered at Terminal': 'DAT',
                'Delivered at Place': 'DAP',
                'Delivered Duty Paid': 'DDP',
            },
            modality: ['Plan Vallejo', 'Courier', 'Ordinary'],
        },
        countries: [],
        cities: [],
        states: [],
        headers: [
            {
                text: 'INDEX',
                value: 'index',
                align: 'center',
                sortable: false,
                width: '95',
            },
            {
                text: 'CODE',
                value: 'code',
                align: 'center',
                sortable: false,
                width: '120',
            },
            {
                text: 'ORDER',
                value: 'order',
                align: 'center',
                sortable: false,
                width: '120',
            },
            {
                text: 'ITEM',
                value: 'description',
                align: 'left',
                sortable: false,
            },
            {
                text: 'SUPPLIER',
                value: 'supplier',
                align: 'left',
                sortable: false,
            },
            {
                text: 'PURCHASED',
                value: 'quantity',
                align: 'center',
                sortable: false,
                width: '95',
            },
            {
                text: 'RECEIVED',
                value: 'received',
                align: 'center',
                sortable: false,
                width: '95',
            },
            {
                text: 'PROCESSED',
                value: 'processed',
                align: 'center',
                sortable: false,
                width: '95',
            },
            {
                text: 'PRICE',
                value: 'price',
                align: 'center',
                sortable: false,
            },
            {
                text: 'TOTAL',
                value: 'totalPrice',
                align: 'center',
                sortable: false,
            },
        ],
        listCountries: [],
        shortNameCity: null,
        suppliers: new Set(),
        shipmentForm: false,
        shipments: [],
        originalrequest: {},
        request: { project: { name: '' }, suppliers: [] },
        companyId: JSON.parse(localStorage.getItem('company')),
        itemValues: [],
        shipmentKey: 0,
        localAttachments: {},
        user: {},
    }),
    computed: {
        formattedIncoterms() {
            return Object.entries(this.comexData.incoterms).map(
                ([key, value]) => ({
                    text: `(${value}) ${key}`,
                    value,
                })
            )
        },
        formattedYear() {
            let timestamp

            if (
                typeof this.request.purchasedDate === 'object' &&
                '_seconds' in this.request.purchasedDate
            ) {
                timestamp = this.request.purchasedDate._seconds * 1000
            } else if (
                typeof this.request.purchasedDate === 'object' &&
                'seconds' in this.request.purchasedDate
            ) {
                timestamp = this.request.purchasedDate.seconds * 1000
            } else {
                return 'Invalid Date'
            }
            const date = new Date(timestamp)
            return date.getFullYear()
        },
        formattedSuppliers() {
            const suppliers = this.request.suppliers
            if (Array.isArray(suppliers)) {
                return suppliers.join(', ')
            }
            return suppliers || ''
        },
        validateGeneralForm: function() {
            let diff = objDiff(this.originalrequest, this.request)
            delete diff.year
            delete diff.suppliers
            return Object.keys(diff).length > 0
        },
        getReceived() {
            return () => {
                const items = this.request?.items || []
                let received = []

                items.forEach(item => {
                    if (item.entries) {
                        const code = item.code
                        let quantityReceived = item.entries.reduce(
                            (sum, entry) => sum + entry.quantity,
                            0
                        )
                        received.push({ code, quantityReceived })
                    }
                })

                return id => {
                    const item = received.find(obj => obj.code === id)
                    return item ? item.quantityReceived : 0
                }
            }
        },
        getProcessed() {
            return () => {
                const items = []
                const comex = this.request?.comex || []
                comex.forEach(obj => {
                    if (obj.items) {
                        items.push(...obj.items)
                    }
                })
                let processed = []

                items.forEach(item => {
                    const code = item.code
                    const quantityProcessed = item.quantity

                    const processedIndex = processed.findIndex(
                        obj1 => obj1.code === code
                    )
                    if (processedIndex > -1) {
                        processed[
                            processedIndex
                        ].quantityProcessed += quantityProcessed
                    } else {
                        processed.push({ code, quantityProcessed })
                    }
                })

                return id => {
                    const item = processed.find(obj => obj.code === id)
                    return item ? item.quantityProcessed : 0
                }
            }
        },
        validateNewShipment() {
            const validate = this.itemValues.reduce((accum, obj) => {
                return accum + (obj.maxValue || 0)
            }, 0)
            return validate
        },
        validateFinalizeCOMEX() {
            let numberItems = this.request.items.length
            this.request.items.forEach(item => {
                if (
                    this.getReceived()(item.code) -
                        this.getProcessed()(item.code) ===
                    0
                ) {
                    numberItems -= 1
                }
            })
            return numberItems === 0
        },
    },
    async mounted() {
        try {
            this.loading = true
            const {
                data: { user },
            } = await API.getMyInfo()
            this.user = user
            this.originalrequest = _.cloneDeep(this.purchase)
            this.request = _.cloneDeep(this.purchase)
            if (!this.purchase.comex) {
                this.originalrequest.comex = []
                this.request.comex = []
            }
            this.itemsMaxValues()
            this.getSuppliers()
            this.request.suppliers = [...this.suppliers]
            this.request.year = this.formattedYear
            this.listCountries = countrycitystate.getCountries()
            this.countries = this.listCountries.map(x => x?.name)
            if (this.request.originCountry) {
                this.selectState(this.request.originCountry)
            }

            if (this.request.originState) {
                this.selectCity(this.request.originState)
            }
            await this.getShipments()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        close() {
            this.$emit('close')
        },
        async saveComexForm() {
            try {
                this.loading2 = true
                let comexData = _.cloneDeep(this.request)
                delete comexData.year
                delete comexData.suppliers
                if (
                    comexData.modality != 'Plan Vallejo' &&
                    comexData.maxExpDate
                ) {
                    delete comexData.maxExpDate
                }
                const diff = objDiff(this.originalrequest, comexData)
                diff.id = this.request.id
                if (diff.comex) {
                    diff.comex = this.request.comex
                }
                if (Object.keys(this.localAttachments).length != 0) {
                    await this.saveFile()
                }
                await API.updatePurchaseByComex(diff)
                this.originalrequest = _.cloneDeep(this.request)
                await this.getShipments()
                this.shipmentKey++
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading2 = false
            }
        },
        selectState: function(event) {
            const index = this.listCountries.findIndex(x => x?.name === event)
            this.shortNameCity = this.listCountries[index].shortName
            this.states = countrycitystate.getStatesByShort(this.shortNameCity)
        },
        selectCity: function(event) {
            let data = countrycitystate.getCities(this.shortNameCity, event)
            if (data.length == 0) {
                this.cities = []
            }
            data.forEach(city => {
                this.cities.push(city)
            })
        },
        validateItemQty(item) {
            let entryQty = 0
            if (item.entries && item.entries.length > 0) {
                entryQty = item.entries.reduce(
                    (accumulator, entry) => entry.quantity + accumulator,
                    0
                )
            }
            if (entryQty >= item.quantity) {
                return 'mt-3 font-weight-medium green--text'
            } else if (entryQty == 0) {
                return 'mt-3 font-weight-medium red--text'
            } else {
                return 'mt-3 font-weight-medium orange--text'
            }
        },
        validateItemProcessed(code) {
            if (this.getReceived()(code) < this.getProcessed()(code)) {
                return 'mt-3 font-weight-medium red--text'
            } else if (this.getReceived()(code) == this.getProcessed()(code)) {
                return 'mt-3 font-weight-medium green--text'
            } else if (this.getProcessed()(code) == 0) {
                return 'mt-3 font-weight-medium red--text'
            } else {
                return 'mt-3 font-weight-medium orange--text'
            }
        },
        numberFormat(amount) {
            const value = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                maximumFractionDigits: 2,
            }).format(amount)
            return value
        },
        getSupplier(item) {
            const selectedQuote = item.quotes?.find(quote => quote.able)
            return selectedQuote ? selectedQuote.supplier?.name : undefined
        },
        async finalizeComex() {
            try {
                this.loading2 = true
                this.loading = true
                const { pdf } = await this.print()
                const fileName = `ForeignTrade_${this.request.number}`
                let pdfFile = this.blobToFile(pdf, fileName)
                await saveFile(
                    pdfFile,
                    `${this.companyId}/purchases/${this.request.id}/AttachmentsCOMEX/COMEX`
                )
                await API.updatePurchaseByComex({
                    comexFinished: true,
                    attachedCOMEX: pdfFile.name,
                    id: this.request.id,
                })
                this.$emit('finalizeCOMEX', this.request.id)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading2 = false
                this.loading = false
                this.close()
            }
        },
        getSuppliers() {
            if (
                this.request.items &&
                Array.isArray(this.request.items) &&
                this.request.items.length > 0
            ) {
                this.request.items.forEach(item => {
                    if (
                        item.quotes &&
                        Array.isArray(item.quotes) &&
                        item.quotes.length > 0
                    ) {
                        item.quotes.forEach(quote => {
                            if (
                                quote.able === true &&
                                quote.supplier &&
                                quote.supplier.name
                            ) {
                                this.suppliers.add(quote.supplier.name)
                            }
                        })
                    }
                })
            }
        },
        createShipmentForm() {
            let shipment = {}
            shipment.id = uuidv4().substring(0, 4)
            shipment.editable = true
            shipment.newShipment = true
            shipment.items = this.itemsStructureData()
            shipment.iterator = this.shipments.length + 1
            this.shipments.push(shipment)
        },
        itemsStructureData() {
            const codes = this.getCodesOfPurchaseItems()
            let itemsSelected = []
            codes.forEach(code => {
                const received = this.getReceived()(code)
                const processed = this.getProcessed()(code)
                if (received != 0 && processed < received) {
                    const purchaseItem = this.request.items.find(
                        item => item.code === code
                    )
                    const supplier = purchaseItem.quotes.find(
                        quote => quote.able === true
                    )
                    const shipmentItem = {
                        code,
                        oc: purchaseItem.purchaseOrder?.orderNumber || '',
                        description: purchaseItem.description,
                        quantity: 0,
                        id: purchaseItem.id,
                        supplier: supplier.supplier,
                        unitPrice: supplier.price,
                        totalPrice: 0,
                    }

                    itemsSelected.push(shipmentItem)
                }
            })
            return itemsSelected
        },
        itemsMaxValues() {
            this.itemValues = []
            this.request.items.forEach(obj => {
                const received = this.getReceived()(obj.code)
                const processed = this.getProcessed()(obj.code)
                let maxValue = 0
                if (received - processed > 0) {
                    maxValue = received - processed
                }
                this.itemValues.push({
                    code: obj.code,
                    description: obj.description,
                    maxValue,
                    received: received,
                    processed: processed,
                })
            })
        },
        getCodesOfPurchaseItems() {
            let codesItems = []
            this.request.items.forEach(item => {
                codesItems.push(item.code)
            })
            return codesItems
        },
        async getOrderURL(item) {
            const path = `${this.companyId}/purchases/${this.request.id}/purchase_order`
            const storageRef = await storage().ref(path)
            const docRef = storageRef.child(item.purchaseOrder.file)

            try {
                item.purchaseOrder.fileURL = await docRef.getDownloadURL()
            } catch (error) {
                console.error('Error al obtener la URL de descarga:', error)
                item.purchaseOrder.fileURL = null
            }
            return item.purchaseOrder.fileURL
        },
        openFile(url) {
            window.open(url, '_blank')
        },
        async print() {
            try {
                this.loading = true
                const pdf = await printCOMEXPDF(
                    this.request,
                    this.formattedSuppliers,
                    this.formattedYear
                )
                return { pdf }
            } catch (error) {
                console.error(error)
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        deleteShipment(shipmentId) {
            const index = this.shipments.findIndex(
                item => item.id === shipmentId
            )
            if (index > -1) {
                this.shipments.splice(index, 1)
                const comexIndex = this.request.comex.findIndex(
                    obj => obj.id === shipmentId
                )
                if (comexIndex > -1) {
                    this.request.comex.splice(comexIndex, 1)
                }
            }
            this.itemsMaxValues()
        },
        async deleteFile(file) {
            const path = `${this.companyId}/purchases/${this.request.id}/AttachmentsCOMEX`
            const storageRef = await storage().ref(path)
            let fileNames = []
            try {
                const result = await storageRef.listAll()
                if (result.items.length > 0 || result.prefixes.length > 0) {
                    fileNames = result.items.map(item => item.name)
                }
            } catch (error) {
                console.error('The folder is empty:', error)
            }
            if (fileNames.includes(file)) {
                await deleteFile(
                    file,
                    `${this.companyId}/purchases/${this.request.id}/AttachmentsCOMEX`
                )
            }
        },
        async saveFile() {
            try {
                this.loading = true
                for (const value of Object.values(this.localAttachments)) {
                    await saveFile(
                        value.file,
                        `${this.companyId}/purchases/${this.request.id}/AttachmentsCOMEX`
                    )
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async openAttachedFile(file) {
            await openFile(
                file,
                `${this.companyId}/purchases/${this.request.id}/AttachmentsCOMEX`
            )
        },
        comexShipment(data, items, attachments) {
            const newComex = [...this.request.comex]
            const indexComex = newComex.findIndex(item => item.id === data.id)
            if (indexComex > -1) {
                newComex.splice(indexComex, 1, {
                    ...data,
                    items: [...items],
                })
            } else {
                newComex.push({ ...data, items: [...items] })
            }
            this.request = { ...this.request, comex: newComex }
            this.localAttachments = attachments
            this.itemsMaxValues()
        },
        async handleOpenFile(item) {
            try {
                const fileURL =
                    item.purchaseOrder.fileURL || (await this.getOrderURL(item))
                if (fileURL) {
                    this.openFile(fileURL)
                } else {
                    console.warn('File URL not found.')
                }
            } catch (error) {
                console.error('Error getting file URL: ', error)
            }
        },
        async getShipments() {
            this.shipments.splice(0, this.shipments.length)
            if (this.request.comex.length > 0) {
                let iterator = 0
                this.request.comex.forEach(item => {
                    iterator += 1
                    const { items, id, ...shipmentFormData } = item

                    this.shipments.push({
                        shipmentFormData,
                        id,
                        items,
                        iterator,
                    })
                })
            }
        },
        async showCOMEXFile() {
            if (this.request.attachedCOMEX) {
                await openFile(
                    this.request.attachedCOMEX,
                    `${this.companyId}/purchases/${this.request.id}/AttachmentsCOMEX/COMEX`
                )
            }
        },
        blobToFile(blob, fileName) {
            return new File([blob], fileName, {
                type: blob.type,
                lastModified: new Date().getTime(),
            })
        },
        async closeByCOMEX() {
            try {
                this.loading2 = true
                this.loading = true

                await API.updatePurchaseByComex({
                    closeByCOMEX: this.user.id,
                    id: this.request.id,
                })

                await API.closePurchaseRequest({
                    id: this.request.id,
                    notificationId: 'pFDh25EcR74EMiuypq8M',
                    ...(this.request.closingFile
                        ? { closingFile: this.request.closingFile.name }
                        : {}),
                })
                if (this.request.closingFile) {
                    await saveFile(
                        this.request.closingFile,
                        `${this.companyId}/purchases/${this.request.id}/closing`
                    )
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading2 = false
                this.loading = false
                this.close()
            }
        },
    },
}
</script>
<style>
.sticky-header {
    position: sticky;
    top: 0;
    z-index: 20;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
</style>
